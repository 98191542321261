import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_programming.svg';

function ServicesOperationsPage() {

    return <ContentPage>
        <h1>System Management and Support</h1>
        <PageImage image={image} alt='System monitoring' />
        <p>A great application is only useful if it works properly.  Our systems are built to be robust and secure, but
            it is still wise to keep a close watch on them to ensure that they are running at optimum performance.</p>
        <p>Our system management and support services include:</p>
        <ul>
            <li>System health monitoring and alerting, to detect problems before they cause damage</li>
            <li>Daily overview checks, to monitor trends and identify areas that may need additional attention.</li>
            <li>Monthly status reports, to give you an overview of the key system performance metrics and description 
                of any notable events during the month.</li>
            <li>User help desk, to resolve probelms and provide guidance.</li>
        </ul>
    </ContentPage>

}

export default ServicesOperationsPage;